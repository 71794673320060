.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-link {
  @apply btn btn-sm bg-slate-200 dark:bg-slate-700 dark:text-white;
}

.nav-link.accent {
  @apply bg-accent;
}

.nav-link.active {
  @apply bg-slate-800 text-white dark:bg-slate-100 dark:text-black;
}
